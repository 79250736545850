export const procrastination = {
  Name: 'procrastination',
  Landing: {
    heading: 'Overcome procrastination, reduce anxiety, and boost productivity',
    description:
      'Increase focus, energy, and determination using artificial intelligence.',
  },
  Quiz: {
    quizFlow: [
      {
        image: '/static/icons/1.svg',
        question: 'What is your age?',
        questionCode: 'age',
        subtitle:
          'This information will be used to determine your tendency to delay tasks.',
        answers: [
          '18 - 24 years',
          '25 - 34 years',
          '35 - 44 years',
          '45 - 54 years',
          '55+ years',
        ],
        type: 'question',
      },
      {
        image: '/static/icons/2.svg',
        question: 'Do you often leave tasks to the last minute?',
        questionCode: 'task_delay_frequency',
        answers: ['All the time', 'Frequently', 'Rarely', 'Never'],
        type: 'question',
      },
      {
        image: '/static/icons/9.svg',
        questionCode: 'agree_statement_1',
        question: 'Do you agree with the following statement?',
        subtitle: '"I struggle to prioritize my tasks effectively."',
        answers: ['Strongly disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly agree'],
        type: 'question',
      },
      {
        image: '/static/icons/3.svg',
        questionCode: 'discomfort_in_task_completion',
        question: 'Do you feel discomfort or anxiety when you are completing tasks?',
        answers: ['All the time', 'Frequently', 'Rarely', 'Never'],
        type: 'question',
      },
      {
        image: '/static/icons/4.svg',
        question: 'Do any of the following options apply to you?',
        questionCode: 'characteristics',
        subtitle: 'Select as many or few options as you like.',
        answers: [
          'I struggle with motivation',
          'I often feel overwhelmed with tasks',
          'I struggle with concentration',
          'I tend to distract myself from tasks with other activities',
          'I tend to underestimate the time needed to complete tasks',
          'I have difficulty with decision-making',
          'Other',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        image: '/static/icons/1.svg',
        question: "How long do you think you've been delaying tasks?",
        questionCode: 'task_delay_duration',
        answers: [
          '0-12 months',
          '12-24 months',
          '24-36 months',
          'More than 3 years',
          'As long as I can remember',
          "Don't know",
        ],
        type: 'question',
      },
      {
        image: '/static/icons/8.svg',
        question: 'Do you agree with the following statement?',
        questionCode: 'satisfied_with_task_completion',
        subtitle: '"I am satisfied with my ability to complete tasks on time."',
        answers: ['Strongly disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly agree'],
        type: 'question',
      },
      {
        image: '/static/icons/7.svg',
        question: 'What do you think contributes to your tendency to delay tasks?',
        questionCode: 'task_delay_reasons',
        subtitle: 'Select as many or few options as you like.',
        answers: [
          'Perfectionism',
          'Fear of failure',
          'Lack of motivation',
          'Difficulty with decision-making',
          'Feeling overwhelmed',
          'Other',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        type: 'info',
        description:
          "“You’re not alone - procrastination often causes stress, guilt, or anxiety related to the loss of productivity or missed opportunities, which can promote even more negative thinking.\n\n If you ever tried to overcome procrastination and failed, it's probably because you didn't know what your triggers are.\n\nTo boost your productivity once and for all, you need to understand your triggers first and then follow a system that works specifically for you!\"\n\nScience and Research Team at Minderse.",
        button: 'Continue',
      },
      {
        image: '/static/icons/5.svg',
        question:
          'How do you typically respond when faced with a task that you don’t want to do?',
        questionCode: 'response_to_task',
        subtitle: 'Select as many or few options as you like.',
        answers: [
          'Avoid the task and do something else',
          'Wait until the last minute to start',
          'Break the task into smaller parts and tackle them one at a time',
          'Ask for help',
          'Other',
        ],
        type: 'question',
      },
      {
        image: '/static/icons/6.svg',
        question:
          'Which of the following best describes your approach to setting and achieving goals?',
        questionCode: 'goal_approach',
        subtitle: 'Select as many or few options as you like.',
        answers: [
          'I set goals, but struggle to make progress on them',
          'I have a hard time setting goals in the first place',
          'I set goals and achieve them consistently',
          'I don’t set goals',
          'Other',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        type: 'info',
        image: '/static/icons/brenda.png',
        heading: 'TAKE CONTROL OF YOUR PRODUCTIVITY!',
        description:
          '"I used to struggle with procrastination and felt like I was constantly behind. Minderse helped me identify my procrastination habits and gave me the tools to improve my productivity. Now, I feel more in control of my time and able to tackle tasks with confidence.\r\n\r\nIf you’re looking to break the cycle of procrastination, I highly recommend trying Minderse."',
        button: 'Continue',
      },
      {
        question:
          'Which habits or activities do you believe are influenced by your procrastination?',
        questionCode: 'insecurity_influenced',

        subtitle:
          'Please select all of the options that apply to you - this will give us insight into your personal habits.',
        answers: [
          {
            image: '/static/icons/relationship.png',
            text: 'Relationships',
          },
          {
            image: '/static/icons/barbell.png',
            text: 'Exercise',
          },
          {
            image: '/static/icons/apple.png',
            text: 'Proper nutrition',
          },
          {
            image: '/static/icons/laptop.png',
            text: 'Working',
          },
          {
            image: '/static/icons/sleep.png',
            text: 'Healthy sleep',
          },
          {
            image: '/static/icons/friendships.png',
            text: 'Friendships',
          },
          {
            image: '/static/icons/speaking.png',
            text: 'Speaking up',
          },
          {
            image: '/static/icons/dart.png',
            text: 'Achieving goals',
          },
        ],
        isImageType: true,
        isMultiSelect: true,
        type: 'question',
      },
      {
        question: 'Do you have any habits that you would like to stop or break?',
        questionCode: 'want_to_stop',
        subtitle: 'Select as many options as you wish.',
        answers: [
          {
            image: '/static/icons/alcohol.png',
            text: 'Drinking',
          },
          {
            image: '/static/icons/social-media.png',
            text: 'Social media',
          },
          {
            image: '/static/icons/sad.png',
            text: 'Insecurity',
          },
          {
            image: '/static/icons/hamburger.png',
            text: 'Fast food',
          },
          {
            image: '/static/icons/clock.png',
            text: 'Losing sleep',
          },
          {
            image: '/static/icons/biting.png',
            text: 'Nail-biting',
          },
          {
            image: '/static/icons/television.png',
            text: 'Binge watching',
          },
          {
            image: '/static/icons/smoking.png',
            text: 'Smoking',
          },
        ],
        isImageType: true,
        isMultiSelect: true,
        type: 'question',
      },
      {
        type: 'info',
        image: '/static/icons/stats.png',
        heading: 'Ready to see your plan?',
        description:
          "Minderse's behavior change plans are grounded in the latest behavioral science research and expertly crafted by psychologists, coaches, and behavioral specialists.\r\n\r\nOur personalized approach targets the underlying causes of behavior, enabling long-term, sustainable habit change.",
        button: 'Continue',
      },
      {
        image: '/static/icons/2.svg',
        question: "Tell us what you'd be interested in your plan.",
        subtitle: 'Select as many options as you wish.',
        isMultiSelect: true,
        questionCode: 'what_interests',
        answers: [
          'Self-worth',
          'Productivity',
          'Self-discipline',
          'Eliminating stress, anxiety, and depression',
          'Motivation',
          'Getting rid of bad habits',
        ],
        type: 'question',
      },
      {
        image: '/static/icons/1.svg',
        question:
          'What is the amount of time you are willing to commit to self-improvement on a daily basis?',
        questionCode: 'ammount_of_daily_time',
        answers: ['5 min / day', '10 min / day', '15 min / day', '20+ min / day'],
        type: 'question',
      },
      {
        type: 'email',
      },
    ],
  },
  GetStarted: {
    description:
      'We have developed a plan to help you overcome procrastination and achieve your goals.',
    button: 'Continue',
  },
  Summary: {
    heading: 'Stop procrastinating, accomplish more, and boost your confidence today',
    resultsTitle: 'Procrastination score',
    suggestedPlan: 'anti-procrastination',
    issue: 'procrastination',
    summary: [
      {
        heading: 'Lack of Internal Motivation',
        description:
          'It appears that you primarily rely on extrinsic and short-term motivation.',
      },
      {
        heading: 'Low Frustration Tolerance',
        description:
          'It seems that you often add negative thoughts to already frustrating situations.',
      },
      {
        heading: 'Perfectionism',
        description:
          'It appears that you might be a perfectionist and are never quite satisfied with your work.',
      },
      {
        heading: 'Avoidance of Discomfort',
        description:
          'It seems that you tend to focus on and amplify any discomfort you feel.',
      },
    ],
  },
  Purchase: {
    suggestedPlan: 'Anti-Procrastination',
    promise: 'boost your productivity',
    subtitle:
      'Overcome procrastination and feel more productive, form healthy habits, reduce anxious thoughts',
    benefits: [
      {
        heading: 'Maximize your productivity.',
        description:
          'Achieve your objectives faster and more efficiently by utilizing your strengths.',
      },
      {
        heading: 'Boost your confidence.',
        description:
          'Develop a positive self-image and the skills to tackle challenges with assurance.',
      },
      {
        heading: 'Strengthen your resolve.',
        description:
          'Create effective plans and self-discipline strategies to stay on track with your goals.',
      },
      {
        heading: 'Decrease stress and anxiety.',
        description:
          'Discover ways to handle stress and manage anxiety for a calmer mind.',
      },
      {
        heading: 'Transform into your best self.',
        description:
          'Focus on long-term personal development for a more peaceful and fulfilling life.',
      },
      {
        heading: 'Develop meaningful relationships.',
        description:
          'Improve your communication and empathy skills to create deeper connections with others.',
      },
    ],
    chart: {
      start: 'Your Procrastination Level',
      end: 'Your Target Level',
    },
    newspaper:
      'Transform your life with our proven plan to beat procrastination and boost willpower.\r\n\r\nTake the first step towards becoming your best self today.',
    scienceBacked: [
      {
        image: '/static/icons/bed.png',
        heading: 'Improved sleep quality',
        description:
          'Low self-confidence can seriously disrupt your sleep and significantly reduce sleep quality by at least 36%.',
      },
      {
        image: '/static/icons/relieved.png',
        heading: 'Boosted positive feelings',
        description:
          'Embrace your true self and experience the joy of increased happiness with self-acceptance.',
      },
      {
        image: '/static/icons/cash.png',
        heading: 'Earn more money',
        description:
          'Taking care of mental health leads to increased productivity, better decision making, and improved relationships, leading to greater success and earning potential.',
      },
      {
        image: '/static/icons/brain.png',
        heading: 'Increased cognitive ability',
        description:
          'Enhancing self-acceptance has been shown to increase cognitive processing power, according to a study conducted by scientists in Utah.',
      },
    ],
  },
};
