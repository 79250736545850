import { selfEsteem } from './Funnels/self-esteem';
import { chakras } from './Funnels/chakras';
import { procrastination } from './Funnels/procrastination';
import { burnout } from './Funnels/burnout';
import { adhd } from './Funnels/adhd';
import { npd } from './Funnels/npd';
import { bpd } from './Funnels/bpd';
import { childhoodTrauma } from './Funnels/ctr';
import { habits } from './Funnels/habits';
import { findProductBySku } from '../../helpers/products';
import { IProduct } from '../../types';

export const Funnels = {
  self_esteem: selfEsteem,
  procrastination,
  burnout,
  adhd,
  chakras,
  habits,
  npd,
  ctr: childhoodTrauma,
  default: selfEsteem,
  bpd,
};

export const FunnelRoutes = {
  HOME: '/',
  QUIZ: '/quiz',
  CHECKING: '/checking',
  SUMMARY: '/summary',
  PURCHASE_PLAN: '/purchase-plan',
  PURCHASE_TRIAL: '/purchase-trial',
  UPSELL_1: '/cheatcode-upsell',
  UPSELL_2: '/premium-upsell',
  UPSELL_3: '/mental-health-workbook',
  UPSELL_4: '/annual-plan-upgrade',
  REGISTER: '/register',
  THANK_YOU: '/thank-you',
};

export const UpsellsOrder = {
  default: [
    FunnelRoutes.UPSELL_4,
    FunnelRoutes.UPSELL_3,
    FunnelRoutes.UPSELL_1,
    FunnelRoutes.UPSELL_2,
  ],
};

// if paymentType is set, summary will lead to appropriate page.
export const RoutesOrder = {
  default: [
    FunnelRoutes.HOME,
    FunnelRoutes.QUIZ,
    FunnelRoutes.CHECKING,
    FunnelRoutes.SUMMARY,
    FunnelRoutes.PURCHASE_PLAN,
    FunnelRoutes.REGISTER,
    FunnelRoutes.THANK_YOU,
  ],
  ctr: [
    FunnelRoutes.HOME,
    FunnelRoutes.QUIZ,
    FunnelRoutes.CHECKING,
    FunnelRoutes.PURCHASE_TRIAL,
    FunnelRoutes.REGISTER,
    FunnelRoutes.THANK_YOU,
  ],
};

export const ProductSkus = {
  sixMonthPlan: 'sixMonthPlan',
  sixMonthPlanDiscounted: 'sixMonthPlanDiscounted',
  sixMonthPlanEmail: 'sixMonthPlanEmail',
  threeMonthPlan: 'threeMonthPlan',
  oneMonthPlan: 'oneMonthPlan',
  threeMonthsTrialPlan: 'threeMonthsTrialPlan',
  premiumUpsell: 'premiumUpsell',
  cheatcodeUpsell: 'cheatcodeUpsell',
  mentalHealthWorkbookUpsell: 'mentalHealthWorkbookUpsell',
  annualPlanUpgrade: 'annualPlanUpgrade',
};

export const getProducts = (products: IProduct[]) => ({
  sixMonthPlan: findProductBySku(
    products,
    process.env['REACT_APP_SIX_MONTHS_PLAN'] || ProductSkus.sixMonthPlan,
  ),
  sixMonthPlanDiscounted: findProductBySku(
    products,
    process.env['REACT_APP_SIX_MONTHS_PLAN_DISCOUNTED'] ||
      ProductSkus.sixMonthPlanDiscounted,
  ),
  sixMonthPlanEmail: findProductBySku(
    products,
    process.env['REACT_APP_SIX_MONTHS_PLAN_EMAIL'] || ProductSkus.sixMonthPlanEmail,
  ),
  threeMonthPlan: findProductBySku(
    products,
    process.env['REACT_APP_THREE_MONTHS_PLAN'] || ProductSkus.threeMonthPlan,
  ),
  oneMonthPlan: findProductBySku(
    products,
    process.env['REACT_APP_ONE_MONTH_PLAN'] || ProductSkus.oneMonthPlan,
  ),
  threeMonthsTrialPlan: findProductBySku(
    products,
    process.env['REACT_APP_THREE_MONTHS_TRIAL_PLAN'] || ProductSkus.threeMonthsTrialPlan,
  ),
  premiumUpsell: findProductBySku(products, ProductSkus.premiumUpsell),
  cheatcodeUpsell: findProductBySku(products, ProductSkus.cheatcodeUpsell),
  mentalHealthWorkbookUpsell: findProductBySku(
    products,
    ProductSkus.mentalHealthWorkbookUpsell,
  ),
  annualPlanUpgrade: findProductBySku(products, ProductSkus.annualPlanUpgrade),
});

export const ProjectName = 'Minderse';
